import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <img src="/img/client-logos/bluesky.png" alt="Bluesky Interactive" className="client-logo" />
    <h3>{`The Client`}</h3>
    <p><a parentName="p" {...{
        "href": "http://www.blueskyinteractive.co.uk"
      }}>{`Bluesky Interactive`}</a>{` are an automotive dealer website design and digital marketing agency who specialise in producing websites for hundreds of dealers across GB and Ireland. Using the latest cutting-edge technology to help dealers sell more vehicles is one of their core value propositions and key differentiators from other dealer website suppliers.`}</p>
    <h3>{`The Problem`}</h3>
    <p>{`Bluesky offer their clients a Lead Management System — Cognition LMS — which enables dealer sales agents to manage enquiries which are submitted via their website. One of the main problems with such website enquiries is that since there is often very little information included, it can be hard for sales agents to qualify the lead, to present them with personalised options and to move them onto the next stage in the sales process (i.e. visiting the showroom and buying).
Therefore, in order to enhance the information available to dealer sales agents, Bluesky reached out to us about implementing Autochart — our automotive lead tracking product — within their sites.`}</p>
    <h3>{`The Solution`}</h3>
    <p>{`I consulted directly with the Bluesky development team to help them integrate Autochart in two separate stages into a website for one of their new clients.
Firstly, we built custom tracking code into the website front-end so that actions which visitors take on the website (searches, vehicle views, enquiry submissions, budget range, etc) are sent to Autochart. Secondly, we used the Autochart .NET SDK inside the LMS to retrieve visitor data from Autochart and display it to the user. A curated selection of this data was also included in the email which is automatically sent to the dealer when an enquiry is received.`}</p>
    <h3>{`The Result`}</h3>
    <p>{`Not only can Bluesky now offer their clients advanced profile information on their website leads, but they can do so through their existing LMS, allowing the dealer sales agent to get a “single view of the prospect”. An added bonus is that after the first implementation, they have productised the offering into their modular COG platform, enabling them to roll it out to both existing and new clients with minimal effort.`}</p>
    <div className="testimonial">
    <div className="testimonial-body">
        <p>
            We’ve worked with Autochart for a number of years now, as we immediately saw the value of the product for our more digitally-enabled clients. Operating largely in the automotive marketplace, we know that our car buyers are part of a slow and research-heavy purchase funnel, and that the better the customer profile we could arm dealers with the higher conversions to sale were going to be. We are seeing increasing numbers of clients take the product, and are getting some really positive feedback across the board.
        </p>
        <p>
            Working with Paul has always been a pleasure – we have created a bespoke integration of the Autochart product into our platform, and Paul has supported us every step of the way. Always willing to add upgrades and improvements, this is the sort of partner relationship that we always aspire to.
        </p>
    </div>
    <div className="testimonial-footer">
        <span className="person-name">Lauren Cooke</span>,&nbsp;
        <span className="person-role">Marketing &amp; Client Relations Manager at Bluesky Interactive</span>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      